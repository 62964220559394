<template>
  <div class="privacy content q-ma-xl" style="max-width: 1200px">
    <section class="section">
      <h2 class="text-h2">Datenschutzhinweise</h2>
      <div class="box content">
        <p><a href="#english">English version: scroll to bottom</a></p>
        <p>
          Ich, Martin Lacroix ("ich"), möchte Sie im Folgenden darüber
          informieren, in welchem Umfang ich personenbezogene Daten von Ihnen
          verarbeite.
        </p>
        <p>
          Ich behalte mir das Recht vor, diese Datenschutzhinweise jederzeit
          unter Beachtung der gesetzlichen Vorschriften zu ändern und
          anzupassen.
        </p>
        <h5 class="title is-5 mt-5">
          1. Verantwortlicher für die Datenverarbeitung
        </h5>
        <p>
          Für die Verarbeitung personenbezogener Daten auf dieser Website ist
          Martin Lacroix verantwortlich. Die Kontaktdaten lauten wie folgt:
        </p>
        Martin Lacroix<br />
        Hunfeld 5<br />
        35396 Gießen<br />
        Tel: 0176 30 50 20 47<br />
        E-Mail ml@martin-lacroix.de<br />

        <h5 class="title is-5 mt-5">2. Datenerhebung auf dieser Website</h5>

        <h6 class="title is-6">a) Wenn Sie meine Website nur besuchen</h6>
        <p>
          Wenn Sie meine Website besuchen, speichern meine Webserver
          standardmäßig temporär die Verbindungsdaten des anfragenden Rechners
          (IP-Adresse), Uhrzeit der Serveranfrage, die Seiten, die Sie bei mir
          besuchen, das Datum und die Dauer des Besuchs, die Erkennungsdaten des
          verwendeten Browser- und Betriebssystem-Typs sowie die Website, von
          der aus Sie mich besuchen (Referrer). Dabei ist mir der Rückschluss
          auf eine natürliche Person aus diesen Daten nicht möglich.
        </p>
        <p>
          Mein Interesse an der Verarbeitung der genannten Nutzungsdaten besteht
          insbesondere darin, die Funktionsfähigkeit und Sicherheit meiner
          informationstechnischen Systeme sicherzustellen und mein Webangebot zu
          optimieren.
        </p>
        <p>
          Die Verarbeitung ist zur Wahrung meiner zuvor genannten berechtigten
          Interessen erforderlich. Die Rechtsgrundlage hierfür ist Art. 6 Abs. 1
          S. 1 lit. f DS-GVO.
        </p>

        <h6 class="title is-6 mt-3">
          b) Wenn Sie mein Kontaktformular oder meine Service-Hotline nutzen
        </h6>
        <p>
          Wenn Sie mein Kontaktformular oder meine Service-Hotline nutzen,
          verarbeite ich Ihren Namen nebst Anrede, Ihre E-Mail-Adresse sowie
          alle weiteren Angaben, die Sie mir hierzu mitteilen, zur Bearbeitung
          Ihrer Anfrage.
        </p>
        <p>
          Die Verarbeitung beruht auf Ihrer freiwillig erteilten Einwilligung.
          Die Rechtsgrundlage hierfür ist Art. 6 Abs. 1 S. 1 lit. a DS-GVO. Ihre
          Einwilligung können Sie jederzeit widerrufen. Der Widerruf hat keinen
          Einfluss auf die Rechtmäßigkeit der Datenverarbeitung, die vor dem
          Widerruf stattgefunden hat. Die Daten werden gelöscht, sobald Ihre
          Anfrage bearbeitet ist, Sie ihre Einwilligung widerrufen haben oder
          mich auffordern, die Daten zu löschen bzw. bis zur Verjährung Ihrer
          Auskunfts- und sonstigen Ansprüchen.
        </p>

        <h6 class="title is-6 mt-3">
          c) Wenn Sie meinen Newsletter abonnieren
        </h6>
        <p>
          Wenn Sie meinen Newsletter abonnieren, erhalten Sie Neuigkeiten,
          Angebote und weitere Informationen zu meinen Produkten und
          Dienstleistungen an die von Ihnen angegebene E-Mail-Adresse. Nach
          Ihrer Anmeldung zu meinen Newsletter erhalten Sie von mir eine
          Bestätigung. Ihre Anmeldung wird dann wirksam, wenn Sie den in der
          E-Mail genannten Link anklicken und somit Ihre E-Mail-Adresse erneut
          bestätigen (Double Opt-In).
        </p>
        <p>
          Die Verarbeitung beruht auf Ihrer freiwillig erteilen Einwilligung.
          Die Rechtsgrundlage hierfür ist Art. 6 Abs. 1 S. 1 lit. a DS-GVO. Ihre
          Einwilligung können Sie jederzeit (z.B. durch Anklicken des in den
          Newsletter-E-Mails enthaltenen Abmeldelinks) widerrufen. Der Widerruf
          hat keinen Einfluss auf die Rechtmäßigkeit der Datenverarbeitung, die
          vor dem Widerruf stattgefunden hat.
        </p>

        <h5 class="title is-5 mt-5">3. Sicherheit</h5>
        <p>
          Ich treffe alle notwendigen technischen und organisatorischen
          Sicherheitsmaßnahmen, um Ihre personenbezogenen Daten vor Verlust und
          Missbrauch zu schützen. Ich speichere Ihre Daten in einer sicheren
          Betriebsumgebung, die nur berechtigten Personen zugänglich ist. Wenn
          Sie mit mir per E-Mail in Kontakt treten wollen, bedenken Sie bitte,
          dass die Vertraulichkeit der übermittelten Informationen auf Grund der
          Gegebenheiten des Internets nie 100%ig gewährleistet werden kann und
          der Inhalt von E-Mails eventuell von unberechtigten Dritten eingesehen
          werden könnte.
        </p>
        <h5 class="title is-5 mt-5">4. Speicherung personenbezogener Daten</h5>
        <p>
          Nach Ablauf der vorgesehenen Speicherdauer, Widerruf einer etwaig
          erteilten Einwilligung oder erfolgreichem Widerspruch gegen die
          Verarbeitung Ihrer personenbezogenen Daten und nach Ablauf steuer- und
          handelsrechtlicher Aufbewahrungsvorschriften werden Ihre
          personenbezogenen Daten vollständig gelöscht, sofern Sie nicht
          ausdrücklich einer darüberhinausgehenden Datenverwendung zugestimmt
          haben oder dies sonst gesetzlich zulässig bzw. erforderlich sein
          sollte.
        </p>

        <h5 class="title is-5 mt-5">
          5. Weitergabe Ihrer personenbezogenen Daten an Dritte
        </h5>
        <p>
          Eine Weitergabe oder eine sonstige Übermittlung Ihrer
          personenbezogenen Daten an Dritte erfolgt nicht, es sei denn, dies ist
          in diesen Datenschutzhinweisen angegeben und die Weitergabe ist zur
          Erreichung des genannten Zwecks erforderlich (z.B. an beauftragte
          technische Dienstleister) oder Sie haben ausdrücklich hierein
          eingewilligt.
        </p>

        <h5 class="title is-5 mt-5">6. Ihre Rechte als betroffene Person</h5>
        <p>Sie haben als betroffene Person im Sinne der DS-GVO das Recht,</p>
        <ul class="">
          <li>
            gemäß Art. 15 DS-GVO Auskunft über Ihre von mir verarbeiteten
            personenbezogenen Daten zu verlangen;
          </li>
          <li>
            gemäß Art. 16 DS-GVO unverzüglich Berichtigung unrichtiger oder
            Vervollständigung Ihrer bei mir gespeicherten personenbezogenen
            Daten zu verlangen;
          </li>
          <li>
            gemäß Art. 17 DS-GVO die Löschung Ihrer bei mir gespeicherten
            personenbezogenen Daten zu verlangen;
          </li>
          <li>
            gemäß Art. 18 DS-GVO die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen;
          </li>
          <li>
            gemäß Art. 20 DS-GVO Ihre personenbezogenen Daten, die Sie mir
            bereitgestellt haben, in einem strukturierten, gängigen und
            maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
            anderen Verantwortlichen zu verlangen.
          </li>
          <li>
            gemäß Art. 7 Abs. 3 DS-GVO eine einmal erteilte Einwilligung
            jederzeit gegenüber mir zu widerrufen. Dies hat zur Folge, dass ich
            die Verarbeitung, die auf dieser Einwilligung beruhte, für die
            Zukunft nicht mehr fortführen darf. Dadurch wird die Rechtmäßigkeit
            der aufgrund der Einwilligung bis zum Widerruf erfolgten
            Verarbeitung nicht berührt.
          </li>
        </ul>
        <hr />
        <h5 class="title is-5 mt-5">Widerspruchs- und Beschwerderecht</h5>
        <p>
          Zudem haben Sie gemäß Art. 21 DS-GVO das Recht, Widerspruch gegen die
          Verarbeitung Ihrer personenbezogenen Daten einzulegen.
        </p>
        <p>
          Legen Sie Widerspruch ein, werde ich Ihre personenbezogenen Daten
          nicht mehr verarbeiten, es sei denn, ich kann zwingende schutzwürdige
          Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
          und Freiheiten überwiegen, oder die Verarbeitung dient der
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
          unsererseits.
        </p>

        <p>
          Im Falle datenschutzrechtlicher Verstöße steht Ihnen als Betroffene/r
          gemäß Art. 77 DS-GVO das Recht zu, sich bei der zuständigen
          Aufsichtsbehörde zu beschweren. Zuständige Aufsichtsbehörde ist der
          Datenschutzbeauftragte des Landes Hessen. Eine Liste der
          Datenschutzbeauftragten sowie deren Kontaktdaten können Sie folgendem
          Link entnehmen:
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            >https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a
          >.
        </p>

        <p>
          Wenn Sie Fragen haben, die Ihnen diese Datenschutzhinweise nicht
          beantworten oder wenn Sie zu einem Punkt vertiefende Informationen
          wünschen, wenden Sie sich bitte an den Datenschutzbeauftragten. Nutzen
          Sie hierzu bitte die oben angegebenen Kontaktdaten.
        </p>
      </div>
    </section>
    <section class="section">
      <h2 id="english" class="text-h2">Data Privacy Policy</h2>
      <div class="box content">
        <p>
          I, Martin Lacroix ("I"), would like to inform you in the following
          about the extent to which I process personal data from you.
        </p>
        <p>
          I reserve the right to change and adapt this data protection notice at
          any time in compliance with the statutory provisions.
        </p>

        <h5 class="title is-5 mt-5">
          1. person responsible for data processing
        </h5>

        <p>
          Martin Lacroix is responsible for the processing of personal data on
          this website. The contact details are as follows:
        </p>
        <p>
          Martin Lacroix<br />
          Hunfeld 5<br />
          35396 Giessen<br />
          Tel: 0176 30 50 20 47<br />
          E-mail info@martin-lacroix.de
        </p>

        <h5 class="title is-5 mt-5">2. data collection on this website</h5>

        <h6 class="title is-6 mt-3">a) If you only visit my website</h6>
        <p>
          When you visit my website, my web servers temporarily store the
          connection data of the requesting computer (IP address), the time of
          the server request, the pages you visit on my website, the date and
          duration of the visit, the identification data of the browser and
          operating system type used and the website from which you visit me
          (referrer). It is not possible for me to draw conclusions about a
          natural person from this data.
        </p>
        <p>
          My interest in processing the aforementioned usage data is in
          particular to ensure the functionality and security of my information
          technology systems and to optimize my website.
        </p>
        <p>
          The processing is necessary to protect my previously mentioned
          legitimate interests. The legal basis for this is Art. 6 para. 1 p. 1
          lit. f DS-GVO.
        </p>

        <h6 class="title is-6 mt-3">
          b) If you use my contact form or my service hotline.
        </h6>
        <p>
          If you use my contact form or my service hotline, I will process your
          name and title, your e-mail address and any other information you
          provide to us in order to process your request.
        </p>
        <p>
          The processing is based on your voluntarily given consent. The legal
          basis for this is Art. 6 para. 1 p. 1 lit. a DS-GVO. You can revoke
          your consent at any time. The revocation does not affect the
          lawfulness of the data processing that took place before the
          revocation. The data will be deleted as soon as your request has been
          processed, you have revoked your consent, or you request us to delete
          the data, or until the statute of limitations for your information and
          other claims has expired.
        </p>

        <h6 class="title is-6 mt-3">c) If you subscribe to my newsletter</h6>
        <p>
          If you subscribe to my newsletter, you will receive news, offers and
          further information about my products and services to the e-mail
          address you have provided. After you have subscribed to my newsletter,
          you will receive a confirmation from us. Your subscription becomes
          effective when you click on the link mentioned in the e-mail and thus
          confirm your e-mail address again (double opt-in).
        </p>
        <p>
          The processing is based on your voluntarily given consent. The legal
          basis for this is Art. 6 para. 1 p. 1 lit. a DS-GVO. You can revoke
          your consent at any time (e.g. by clicking on the unsubscribe link
          contained in the newsletter emails). The revocation does not affect
          the lawfulness of the data processing that took place before the
          revocation.
        </p>

        <h5 class="title is-5 mt-5">3 Security</h5>
        <p>
          I take all necessary technical and organizational security measures to
          protect your personal data from loss and misuse. I store your data in
          a secure operating environment that is only accessible to authorized
          persons. If you wish to contact us by e-mail, please bear in mind
          that, due to the nature of the Internet, the confidentiality of the
          information transmitted can never be 100% guaranteed and the content
          of e-mails could possibly be viewed by unauthorized third parties.
        </p>

        <h5 class="title is-5 mt-5">4. storage of personal data</h5>
        <p>
          After expiration of the intended storage period, revocation of any
          consent given or successful objection to the processing of your
          personal data and after expiration of tax and commercial storage
          regulations, your personal data will be completely deleted, unless you
          have expressly agreed to a further use of data or this should
          otherwise be legally permissible or required.
        </p>

        <h5 class="title is-5 mt-5">
          5. transfer of your personal data to third parties
        </h5>
        <p>
          Your personal data will not be passed on or otherwise transferred to
          third parties unless this is stated in this data protection notice and
          the transfer is necessary to achieve the stated purpose (e.g. to
          commissioned technical service providers) or you have expressly
          consented to this.
        </p>

        <h5 class="title is-5 mt-5">6. your rights as a data subject</h5>
        <p>
          As a data subject within the meaning of the DS-GVO, you have the
          right,
        </p>
        <ul>
          <li>
            to request information about your personal data processed by us in
            accordance with Art. 15 DS-GVO;
          </li>
          <li>
            pursuant to Art. 16 DS-GVO, to request without undue delay
            correction of inaccurate or completion of your personal data stored
            by us;
          </li>
          <li>
            pursuant to Art. 17 DS-GVO, to request the deletion of your personal
            data stored by us;
          </li>
          <li>
            in accordance with Art. 18 DS-GVO, to request the restriction of the
            processing of your personal data;
          </li>
          <li>
            pursuant to Art. 20 DS-GVO, to receive your personal data that you
            have provided to us in a structured, common and machine-readable
            format or to request the transfer to another controller.
          </li>
          <li>
            In accordance with Art. 7 (3) DS-GVO, to revoke a consent once given
            to us at any time. This has the consequence that I may no longer
            continue the processing based on this consent for the future. This
            does not affect the lawfulness of the processing carried out on the
            basis of the consent until the revocation.
          </li>
        </ul>

        <h5 class="title is-5 mt-5">
          Right of objection and right of complaint
        </h5>

        <p>
          In addition, you have the right to object to the processing of your
          personal data in accordance with Art. 21 DS-GVO.
        </p>
        <p>
          If you object, I will no longer process your personal data unless I
          can demonstrate compelling legitimate grounds for the processing that
          override your interests, rights and freedoms, or the processing serves
          to assert, exercise or defend legal claims on my part.
        </p>
        <p>
          In the event of violations of data protection law, you as a data
          subject have the right to complain to the competent supervisory
          authority pursuant to Article 77 DS-GVO. The competent supervisory
          authority is the data protection officer of the state of Hesse. A list
          of data protection officers and their contact details can be found at
          the following link:
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
        </p>
        <p>
          If you have any questions that are not answered by this data
          protection notice or if you would like more detailed information on
          any point, please contact my data protection officer. For this
          purpose, please use the contact details provided above.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Privacy",
};
</script>
